.footer_list {
  display: flex !important;
  justify-content: space-between;
  font-size: smaller;
}

.container-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.footcolorlinks {
  margin-top: 2px;
  font-family: Arial, Helvetica, sans-serif;
}

.footcolorlinks:hover {
  border-bottom: 2px solid white;
}

.nospace {
  margin-top: 10px;
}

.empty_footer_div {
  border: 0.1px solid rgb(141, 137, 137);
  width: 100%;
}

.footerheading {
  font-weight: bold;
  font-size: 20px;
}

.footer_list a {
  text-decoration: none;
  color: inherit;
  text-align: left;
}

.footer_list a:hover {
  border-bottom: 2px solid white;
}

.instaicon_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-top: 20px;
  margin-left: 30px;
}

.footerSocialMediaIcon {
  font-size: medium;
  margin-left: 30px;
}

.footerSocialMediaIcon a:hover {
  border-bottom: none !important;
}
.footerSocialMediaIcon a span:hover {
  color: var(--primary-color) !important;
  font-size: larger;
  font-weight: bold;
}
