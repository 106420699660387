.radio_control {
    padding-top: 0 !important;
}

.btn_checkout {
    font-weight: "600px" !important;
    background-color: #222 !important;
    color: var(--white-color) !important;
    width: 150px;
    padding: 10px 14px;
    border-radius: 23px;
    margin-left: 15px;

}

.btn_checkout:hover {
    background-color: var(--primary-color) !important;
}

.MuiStepLabel-iconContainer .Mui-active {
    color: var(--primary-color) !important;
}

.MuiStepLabel-iconContainer .Mui-completed {
    color: var(--primary-color) !important;
}