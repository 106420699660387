.error-container {
  height: cover;
  width: 80%;
  margin: auto;
  /* max-width: ; auto; */
  text-align: center;
}

.error-container h1 {
  font-size: 300px;
  color: #e5e5e5;
  line-height: 1;
}

.error-container>span {
  font-size: 12px;
  text-transform: uppercase;
  color: #7b7b7b;
  font-weight: 500;
}

.error-container h2 {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 32px;
}

.error-container p {
  margin-bottom: 70px;


  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #848b8a;
  margin-bottom: 15px;
  line-height: 24px;

}

.error-container a {
  color: var(--primary-color);
}