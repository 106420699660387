/* Product box container */
.product-box {
  overflow: hidden;
  position: relative;
  padding: 10px;


  /* transition: box-shadow 0.3s ease; */
}

.product-description {
  line-height: 1;
}

/* Product image container */
.product-img-box {
  background-color: var(--secondary-color);
  border-radius: 0px !important;
  text-align: center;
  position: relative;
  margin-top: 10px;
}

.product-name {
  color: var(--dark-gray-color) !important;
  font-size: 14px !important;
  font-weight: 400 !important;
 
  text-transform: capitalize !important;
  cursor: pointer;
}


.carousel-indicators [data-bs-target] {
  width: 13px !important;
  height: 13px !important;
  border-radius: 100% !important;
  border: 1px solid var(--primary-color) !important;
  background: var(--white-color);
}

.product-name:hover {

  color: var(--primary-color);
}

/* Product image itself */
.product-image {
  padding: 10%;
  width: 100%;
  height: 300px;
  object-fit: contain;
  cursor: pointer;
  transition: transform 0.3s ease;
  mix-blend-mode: multiply;
  background-color: transparent;
}


/* Product icons container */
.product-icons {
  position: absolute;
  top: 60%;
  left: 80%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  width: 40px;
  height: 70px;
  opacity: 0;
  transition: opacity 0.3s ease, transform 1s ease;
  /* Added transform transition */
}

.product-icon:hover {
  color: var(--primary-color);
}

/* Individual product icon */
.product-icon {
  border-bottom: 0.1px solid black;
  color: var(--primary-color);
  font-size: x-small;
  padding-bottom: 2px;
  cursor: pointer;
  transition: transform 1s ease;
}

.product-icon_rs {

  font-size: 14px;
  color: #201f1f;
  font-weight: 500;
}


.product-box:hover .product-icons {
  opacity: 1;
  transform: rotateY(360deg);

}

/* Flip animation for icons */
/* .product-icons:hover {
  animation: flip-icons 1s ease both;
} */

/* @keyframes flip-icons {
  0% {
    transform: rotateY(0deg);
  }

  50% {
    
  }

  100% {
    transform: rotateY(180deg);
  }
} */

/* Flip animation for images */


/* ********************************Add to CArt*********** */

.addtocart {
  display: none;
  border-bottom: 1px solid var(--primary-color);
  animation: Flip-addtocart 1s ease both;
}

.product-box:hover .addtocart {
  cursor: pointer;
  display: block;
  /* Show on hover */
}

/* 
@keyframes Flip-addtocart {
  0% {
    transform: rotateX(0deg);
  }

  50% {
    transform: rotateX(60deg);
  }

  100% {
    transform: rotateX(0deg);
  }
} */

.addtocart {
  display: none;
}

.product-box:hover .product-price{
  display: none;
}
.product-box:hover .addtocart {
  display: block;
}

@media (max-width: 576px) {
  .product-image {
    padding: 20px;
  }

  element.style {
    width: 100%;
  }
}

.likebuttonForMobile {
  display: none;
}

/* 
@media only screen and (min-width: 769px) {
  .product-box:hover .product-image {
    animation: flip-image 2s ease both;
  }
  
  @keyframes flip-image {
    0% {
      transform: rotateY(0deg);
    }
  
    50% {
      transform: rotateY(180deg);
    }
  
    100% {
      transform: rotateY(360deg);
    }
  }
} */
