.web_logo_container {
    display: flex;
    justify-content: center;
}

.mbl_menu_container {
    background-color: var(--white-color);
    min-height: 100vh;
    width: 300px;
    position: fixed;
    top: 0;
    padding: 0px 10px;
    left: 0;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    animation: slideInLeft;
    animation-duration: 1.2s;
}

.mbl_menu_close_icon {
    text-align: end;
    margin-top: 15px;
    margin-right: 10px;
}

.mbl_menu_items {
    display: block;
    text-align: left;
    padding: 0px 15px;
}

.mbl_menu_li a {
    color: var(--colorblack);
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}

.mbl_menu_items ul li a {
    padding: 12px 0;
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, .05);
    font-size: 14px;
    color: #000;
    font-weight: 600;
    text-transform: uppercase;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    line-height: 24px;
}


.select-option {
    color: var(--colorblack);
    font-weight: 500;
    font-size: 12px;
    padding-left: 20px;
    text-transform: uppercase;
  }
