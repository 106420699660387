.fullwidth {
    width: 100%;
}

.ordercheckout_container {
    padding: 15px;
    width: 100%;
    min-height: 100vh;
}

.ordercheckout_container_inside {
    width: 100%;
    display: flex;
}

.ordercheckout_customer_information_container {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px -1px 3px, rgba(50, 50, 93, 0.25) 0px 2px -1px 5px;
    padding: 15px 15px 100px 100px;
}

.ordercheckout_customer_contact_field {
    display: flex;
    gap: 10px;
}

.margin-top {
    margin-top: 15px;
}

.ordercheckout_summarycontainer {
    background-color: var(--secondary-color);
    width: 40%;
    padding: 40px;
}

.order_summary_img_container {
    width: 90px;
    height: 90px;
    border: 1px solid #dedede;
    min-width: 90px;
}

.order_summary_img_container img {
    width: 100%;
    height: 100%;
    mix-blend-mode: multiply;

    object-fit: cover;
    cursor: pointer;
    transition: transform 0.3s ease;
    mix-blend-mode: multiply;
    background-color: transparent;
    cursor: pointer;
    transition: transform 0.3s ease;
    mix-blend-mode: multiply;
    margin: auto;
    display: flex;
}

.ordercheckout_summary_product_container {
    display: flex;
    width: 100%;
    gap: 20px;
    margin-bottom: 10px;
    min-height: 125px;
    border: 1px solid rgb(180, 175, 175);
    padding: 10px;
}

.order_summary_namequantity_container {
    width: 50%;
}

.text-overflow {
    width: 80% !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.order_summary_quantity_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    border: 1px solid var(--colorborder);
    height: 30px;
}

.order_summary_quantity_container input {
    width: 30px;
    height: 25px;
    text-align: center;
    font-size: medium;
}

.order_summary_discount_container {
    display: flex;
    margin-top: 10px;
    gap: 10px;
    margin-top: 15px;
}

.coupon_code_field {
    padding: 10px 15px;
    width: 80%;
    border: 1px solid #dedede;
}

.order_total_container_inside {
    display: flex;
    justify-content: space-between;
}

.order_total_container {
    margin-top: 15px;
}

.placeorder_btn {
    width: 100%;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
    text-transform: uppercase;
    border: 1px solid #dedede;
}

.adress_text {
    text-align: justify;
}

.checkout_heading_text {
    float: left;
}

@media (max-width: 970px) {
    .ordercheckout_container_inside {
        display: block;
        text-align: center;
    }

    .ordercheckout_customer_information_container,
    .ordercheckout_summarycontainer {
        width: 100%;
    }

    .ordercheckout_customer_information_container {
        padding-left: 10px;
    }

}

@media (max-width: 570px) {
    .ordercheckout_customer_contact_field {
        display: flex;
        flex-direction: column;
    }
}

.header-cart-item-rupee {
    display: flex;
    align-items: center;
}

.align-justify {
    text-align: justify;
}


/* ============================================================================================================== */

.paynow_submit_btn {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    padding: 12px 20px !important;
    border: none !important;
    border-radius: 4px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 45px !important;
    cursor: pointer;
    width: 100%;

}

/* .paynow_form{
    width: 20px;
    padding-left: 30px;
} */

.failure_container {
    width: 100%;
    min-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.failure_container_fuild {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 30px;
    min-height: 300px;
}