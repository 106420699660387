.Product_page_product_container{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    justify-content: center;
}
.product_sortby_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}
.sortby_container{
    float: right;
    width: 230px;
}

.sortby_container_main{
    padding-right: 30px;
    display: flex;
    /* justify-content: right !important; */
    height: 50px;
    width: 100%;
}
.product_filter{
    width: 25%;
}
.newProduct_page_container{
    display: flex;
   padding: 20px;
}
.mbl_product_filterbtn_container{
    display: none !important;
}
@media (max-width: 1030px) {
    .Product_page_product_container{
       width: 100%;
    }
}
@media (max-width: 940px) {
    .product_filter , .sortby_container_main{
        display: none;
     }
     .mbl_product_filterbtn_container{
        display: flex !important;
    padding: 20px 30px 0px 30px 
    }

}
.sortby_container label{
    z-index: 0 !important;
}
.no-product_div{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40vh;
    font-size: 30px;
    margin-top: 30px;
    color: var(--colornewprimary);
}