.loading_btn {
    padding: 10px;
   
    width: 200px;
    /* background:  var(--primary-color); */
    border: 0.1px solid rgb(131, 128, 128);
}

.loading_btn:hover {

    color: white;
    background: var(--primary-color);
}