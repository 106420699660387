.numberlist li{
    list-style-type: number;
    color: black;
}
.numberlist li ul li , .listStyle-none ul li ,.dotted li{
    list-style-type: circle;
}
.numberlist li ul{
    padding: 0px 25px;
}
.listStyle-none ul{
    padding: 0px 25px;
}
.listStyle-none{
    font-weight: 700;
}
.listStyle-none li{
    font-weight: 500 !important;
}
.returnpolicy_content{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 20px 30px 20px;
}
.note_pera span{
    font-weight: 700;
    font-size: 16px;
    padding-bottom: 15px;
}
.headingBold{
    font-weight: 700;
}