
.empty_container {
  background: white;


    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.empty_container_fluid {
    padding: 10px;

}

.empty_container_fluid img {
   display: none;
}
.shopnow{
   
}
.empty_container_fluid h3 {
    font-family: Poppins, sans-serif;
    color: #201f1f;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    transition: all 0.3s ease-out 0s;
    font-size: 27px;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .empty_container_fluid h3 {
        font-size: 24px;
    }
}

@media (max-width: 576px) {
    .empty_container_fluid h3 {
        font-size: 20px;
    }
}
