.custom-button {
  position: relative;
  background: none !important;
  width: 160px;
  height: 50px;
  color: black;
  /* Default text color */
  font-size: 16px;
  cursor: pointer;
  border: 1px solid black !important;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: color 0.2s, border-color 0.2s;

}

.custom-button:hover {
  color: white;
  /* Text color on hover */
  border-color: transparent;
  /* Transparent border on hover */
}

.innercontainer {
  position: absolute;
  text-decoration: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: transparent; */
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.1s ease;
  /* Smooth transition for transform */
  transform: translateY(-100%);
  /* Initially translate up by 100% of its own height */
}

.custom-button:hover .innercontainer {
  transform: translateY(0);
  /* Translate back to its original position (0% up) */
  background-color: var(--primary-color);
  /* Background color for fill effect */
  color: white;
  /* Text color change on hover */
}