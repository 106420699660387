@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@400;700&display=swap');
$mobile: 800px;
$ipad:1025px;
$desktop: 1440px;
//Primairy text variable

$DarkBlue:  var(--primary-color);
;
$softRed:   var(--primary-color);
;
//nutruel text varible
$darkGrayishBlue: var(--colorblack);
$darkGrayishBlue: var(--colorblack);
//Dividers
$lightGrayishBlue: hsl(240, 5%, 91%);

//Background gradient:

$softViolet: #ae67e9;
$SoftBlue:  var(--primary-color);
// font family

$fontFamily: 'Kumbh Sans', sans-serif;