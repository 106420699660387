.contactcontainer {
  padding: 80px;
  width: 90%;
  margin: auto;
  color: black;
}

.contact_heading {
  position: relative;
  background-size: cover;
  background-image: url(../../../public/image/about.jpg);
  height: 173px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100%;
  margin: 0;
}

.map iframe {
  width: 100%;
}

.contacticon {
  margin-right: 10px;
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .contact_heading {
    height: 100px;
    /* Adjust height for smaller screens */
  }
}

.c-pointer {
  cursor: pointer;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}

.contacticon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(197, 192, 192);
}

.contacticon:hover {
  background: #000;
  color: white;
}

.iconsContactPage {
  font-size: 18px;
  color: #6c6c6c;
}

.iconsContactPage:hover {
  color: white;
}

.containtlist {
  display: flex;
  margin-top: 20px;
}

.contactp {
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #848b8a;
  margin-bottom: 15px;
  line-height: 24px;
}

.fromstaricon {
  color: red;
  font-size: 5px !important;
  margin-bottom: 10px;
}

.form-error {
  font-size: 12px;
  color: red;
}

.contctinformation {
  color: #606060;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  color: #848b8a;
  line-height: 24px;
}

.submitbtn {
  background: #000;
  width: 150px;
  padding: 10px;
  color: white;
  margin-top: 10px;
  border: none;
  cursor: pointer;
}

.inputbox {
  margin-top: 10px;
}

.ContactUsForMobile{
  margin-top: 0px !important; 
}

@media (min-width: 100px) and (max-width: 991px) {
  .ContactUsForMobile {
    margin-top: 30px !important;
  }
}
/* Responsive Design */

@media (max-width: 767px) {
  .contactcontainer {
    padding: 0px;

  }

  .contctinformation {
    font-size: x-small;

  }

  .contact_heading {
    height: 80px;
  }

  .map iframe {
    height: 200px;
  }

  .containtlist {
    /* flex-direction: column; */
    align-items: flex-start;
    margin-top: 10px;
  }

  .data {
    margin-left: 10px;
  }

  .data li {
    width: auto;
    /* Adjust width as needed */
  }

  .contacticon {
    margin-right: 10px;
    /* Adjust spacing between icon and data */
    margin-bottom: 10px;
    /* Adjust spacing between items */
  }
}

.footerSocialMediaIcon {
  margin-top: 30px;
  font-size: 18px;
  color: #606060;
}

.footerSocialMediaIcon:hover {
  color: var(--primary-color);
  font-weight: bold;
}