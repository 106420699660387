.profile-page-container {

  padding: 50px;
  margin: 20px auto;
  width: 50%;
  height: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  /* Ensure relative positioning for absolute child */
}

.form-container-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 8px;
}

.profile-textfield label {
  z-index: 0;
}

@media (max-width: 570px) {
  .profile-page-container {
    padding: 0px !important;
    width: 90%;
  }
}


/* Custom styles for radio buttons and container */
.radio-container {

  align-items: flex-start;
}

.addresstype_radio {

  /* align-items: center; */
  display: flex !important;
  flex-direction: row !important;
}

.addresstype_radio .MuiFormControlLabel-root {
  margin-right: 20px;
}

/* Custom styles for radio button color */
.addresstype_radio span {
  color: var(--primary-color) !important;

}

.css-dmmspl-MuiFormGroup-root {
  display: flex !important;
  flex-direction: row !important;
  justify-content: start !important;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
}