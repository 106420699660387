.pageheading {
    color: black !important;
}

.bread-crumbangle {
    text-decoration: none;
    margin-left: 3px;
}

.bread-crumbangle:hover {
    color: var(--primary-color) !important;
}