.homepagesliderimage {
  mix-blend-mode: multiply;
  margin-left: auto;
  width: 200px;

}
.DiscovernowBNT{
  color: #000;
  text-decoration: none;
}
.DiscovernowBNT:hover{
  color:var(--primary-color)
}