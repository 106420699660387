.custom-tab-list {
  text-align: left;
  margin-top: 50px;
}

.detailpagetab {
  display: flex;
  justify-content: center;
  align-items: center;
}

.react-tabs {
  width: 100%; /* Ensure tabs take up full width */
}

.react-tabs__tab-list {
  border-bottom: none;
  width: 100%; /* Tab list takes up full width */
  max-width: 100%; /* Optional: Ensure no overflow */
  display: flex;
  justify-content: center; /* Center tabs horizontally */
}

.react-tabs__tab {
  border: none;
  color: black !important;
  padding: 10px 15px;
  cursor: pointer;
  margin-right: 10px;
}

.react-tabs__tab--selected {
  font-weight: bold;
}

.react-tabs__tab-panel {
  width: 80vw; /* Tab panels take up full width */
  border: none;

  padding: 20px;
}

.table_container {
  width: 100%; /* Ensure table container takes up full width */
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.table_container table {
  width: 100%; /* Ensure table takes up full width */
  min-width: 100%; /* Minimum width to prevent horizontal overflow */
  border-collapse: collapse;
}

.table_container table th,
.table_container table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table_container table th {
  font-weight: bold;
}

.general_title {
  font-weight: bold;
}

/* ************rating******** */
.name{
  font-size: 14px !important;
  margin-bottom: 0 !important;
  display: inline-block !important;
  color: #201f1f !important;
}
.reviews-container h3{
  font-family: Poppins, sans-serif;
    color: #201f1f;
    margin-top: 0;
    font-weight: 500;
    line-height: 1.2;
    transition: all .3s ease-out 0s;
}
.reviews-container h5{
  font-size: 14px;
  margin-bottom: 0;
  display: inline-block;
  color: #201f1f;
}
.reviews-container p{
  margin-bottom: 0;
    margin-top: 8px;
    color: #5b5b5b; 
}


/* Add these styles to index.css */

.rating-summary {
  margin-bottom: 20px;
}

.rating-bars {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.rating-bar {
  height: 20px;
  color: rgb(149, 25, 25);
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 5px;
  transition: width 0.3s, background-color 0.3s;
}
