.breadcrumbsLink {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: 500;
    padding: 0 5px;
}
.breadcrumbsLink:hover{
    color: var(--primary-color);
}
.separator {
    margin: 0 5px;
    color: #757575;
}

.breadcrumbsContainer {
    display: inline-flex;
    align-items: center;
}
