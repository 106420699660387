/* **********BAck To Home***************** */
.backToHome {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    color: #fff;
    list-style-type: none;
    background: var(--primary-color);
    margin-right: 20px;
    border: 1px solid var(--primary-color);
    z-index: 999;
}