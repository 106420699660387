.CategoryAndSubcategoryCon {
  padding: 10px;
  background-color: white; /* Add a solid background to hide content behind */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 9999999; /* Ensure it’s above other elements */
  position: relative; /* Makes z-index effective if it isn’t already */
}

.CategoryAndSubcategoryCon ul {
  list-style-type: none;
  /* padding: 15px; */
  width: 150px;
  margin-left: 2px;
}

.CategoryAndSubcategoryCon ul li {
  margin-bottom: 10px;
}

.CategoryAndSubcategoryCon ul li a {
  text-decoration: none !important;
  color: black;
  /* font-weight: bold; */
}

.CategoryAndSubcategoryCon ul ul {
  padding-left: 20px;
}

.bothList {
  color: black;
  word-spacing: -1px;
}

.bothList:hover {
  color: var(--primary-color);
}
