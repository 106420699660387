/* RatingChart.css */
.ratingcntainer{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}
.rating-chart {
    margin: 20px;
  }
  
  .rating-bars {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .outerbar {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    background: beige;
    height: 8px;
  }
  
  .rating-bar {
    height: 8px;
    width: 300px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 5px;
    transition: width 0.3s, background-color 0.3s;
      }
  .average-rating p , .average-rating span{
    color: rgb(3, 141, 99);
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 52px;
    font-family: "Mier bold";
    margin: 0px;
    padding: 0px 5px 0px 0px;
  }
  